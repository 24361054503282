import React from "react";
import "./SkillsCard.css";
import { Category } from "@material-ui/icons";
const SkillsCard = ({ desc, name }) => {
  return (
    <div className="col-sm-10 col-xs-12 col-md-4 col-lg-4 mx-auto ">
      <div className="skills-card">
        <Category style={{fontSize: 80}} className="py-3" />
        <p className="sc-title">{name}</p>
        <p className="sc-desc">{desc}</p>
      </div>
    </div>
  );
};

export default SkillsCard;
