// Firebase imports
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

// Project config
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initializing firebase (condition to prevent unnecessary reinitializations)
if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app();
}

const analytics = firebase.analytics();
analytics.logEvent("notification_received");

// FUNTION FOR ADDING NEW DATA TO THE FIRESTORE (PRIVATE)
export const addPageData = async (page, dataType, data) => {
  let error = "";
  // ************************dataType details**********************
  // var description(String) => page description
  // var images (String) => List of the images in the order they have to be displayed
  // var mainImage(String) => If the page has one main image to be displayed
  // var carousel {} => Object with all the data fields for that particular carousel data required

  // ************************page details**************************
  // home -> desription"" and images[]
  // about -> carousel[{}]
  // blog -> carousel[{}], images(instagram)[]
  // work -> type: branding: images[], title, subtitle, description
  //         type: publicaiton: images[], title, subtitle, description
  //         type: infogrpahics: images[], title, subtitle, description

  // if (!user) return; //To be added to prevent unauthorized access
  const userRef = firestore.doc(`data/${page}`);
  const snapshot = await userRef.get();
  if (dataType === "description") {
    try {
      await userRef.set({ ...snapshot.data(), description: data });
    } catch (err) {
      error = err;
    }
  } else if (dataType === "images") {
    try {
      await userRef.set({
        ...snapshot.data(),
        images: [
          ...data,
          ...(snapshot.data()?.images ? snapshot.data().images : []),
        ],
      });
    } catch (err) {
      error = err;
    }
  } else if (dataType === "mainImage") {
    try {
      await userRef.set({ ...snapshot.data(), mainImage: data });
    } catch (err) {
      error = err;
    }
  } else if (dataType === "carousel") {
    try {
      await userRef.set({
        ...snapshot.data(),
        carousel: [
          data,
          ...(snapshot.data()?.carousel ? snapshot.data().carousel : []),
        ],
      });
    } catch (err) {
      error = err;
    }
  } else if (dataType === "carouselV2") {
    try {
      await userRef.set({
        ...snapshot.data(),
        carousel: [...data],
      });
    } catch (err) {
      error = err;
    }
  } else if (dataType === "title") {
    try {
      await userRef.set({ ...snapshot.data(), title: data });
    } catch (err) {
      error = err;
    }
  } else if (dataType === "subtitle") {
    try {
      await userRef.set({ ...snapshot.data(), subtitle: data });
    } catch (err) {
      error = err;
    }
  }
  if (error) {
    return { status: "error" };
  } else {
    return { status: "success" };
  }
};

// FUNTION FOR EDITING DATA TO THE FIRESTORE (PRIVATE)
export const editPageData = async (user, page, dataType, data) => {
  // OVERWRITES ALL THE FIELDS

  // ************************dataType details**********************
  // var description(String) => page description
  // var images [strings] => List of the images in the order they have to be displayed
  // var mainImage(String) => If the page has one main image to be displayed
  // var carousel[{}] => Object with all the data fields for that particular carousel data required

  // ************************page details**************************
  // home -> desription"" and images[] // carousel [{}]
  // about -> description"" and mainImage"" and carousel[{}]
  // blog -> carousel[{}], images(instagram)[]
  // work -> branding: images[], title, subtitle, description
  //         publicaiton: images[], title, subtitle, description
  //         infogrpahics: images[], title, subtitle, description
  if (!auth.currentUser) return;
  const userRef = firestore.doc(`data/${page}`);
  const snapshot = await userRef.get();
  if (dataType === "description") {
    try {
      await userRef.set({ ...snapshot.data(), description: data });
    } catch (err) {
      console.log(err);
    }
  } else if (dataType === "images") {
    try {
      await userRef.set({
        ...snapshot.data(),
        images: [...data],
      });
    } catch (err) {
      console.log(err);
    }
  } else if (dataType === "mainImage") {
    try {
      await userRef.set({ ...snapshot.data(), mainImage: data });
    } catch (err) {
      console.log(err);
    }
  } else if (dataType === "carousel") {
    try {
      await userRef.set({
        ...snapshot.data(),
        carousel: [data],
      });
    } catch (err) {
      console.log(err);
    }
  } else if (dataType === "title") {
    try {
      await userRef.set({ ...snapshot.data(), title: data });
    } catch (err) {
      console.log(err);
    }
  } else if (dataType === "subtitle") {
    try {
      await userRef.set({ ...snapshot.data(), subtitle: data });
    } catch (err) {
      console.log(err);
    }
  }
};

// FUNCTION TO GET PAGE DATA (PUBLIC)
export const getPageData = async (page) => {
  try {
    const data = await firestore.doc(`data/${page}`).get();
    return data.data();
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

// FUNCTION TO GET ALL DATA (PUBLIC)
export const getAllData = async () => {
  try {
    const data = await firestore.collection("data").get();
    return data.docs.map((doc) => doc.data());
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

// FUNCTION TO GET A BLOG PAGE DATA (PUBLIC)
export const getBlogData = (suffix) => {
  return firestore
    .doc(`blog/${suffix}`)
    .get()
    .then((snapshot) => snapshot.data())
    .catch((err) => console.log(err));
};

// FUNCTION TO PUBLISH A NEW POST (PRIVATE)
export const postBlogData = async (title, suffix, imageLink, blogBody) => {
  if (!suffix || !imageLink || !blogBody || !title) return;
  if (!auth.currentUser) return;
  const suff = suffix.toLowerCase();
  const userRef = firestore.doc(`blog/${suff}`);
  let error = "";
  try {
    await userRef.set({
      imageLink: imageLink,
      blogBody: blogBody,
      title: title,
      time: Date.now(),
      route: suff,
    });
  } catch (err) {
    error = err;
  }
  if (error) {
    return { status: "error" };
  } else {
    return { status: "success" };
  }
};

// FUNCTION TO GET ALL DATA (PRIVATE)
export const getAllBlogData = async () => {
  // if (!auth.currentUser) return;
  try {
    const data = await firestore.collection("blog").get();
    return data.docs.map((doc) => doc.data());
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export const deleteBlogPost = async (title) => {
  if (!auth.currentUser) return;
  let error = "";
  try {
    await firestore.collection("blog").doc(title).delete();
  } catch (err) {
    error = err;
  }
  if (error) return { status: "error" };
  else return { status: "success" };
};

// Firebase exports
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
