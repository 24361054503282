import React from "react";
import './ClientCard.css'

const ClientCard = ({name,feedback,designation}) => {
  return (
    <div className=" col-sm-10 col-xs-12 col-md-4 col-lg-4 mx-auto my-2 ">
      <div className="client-card">
      <p className="client-name"><strong>{name}</strong></p>
      <p><strong>{designation}</strong></p>
      <p>
       {feedback}
      </p>
      </div>

    </div>
  );
};

export default ClientCard;
