import React, { useEffect, useState } from "react";
import { getBlogData } from "../../firebaseConfig";
import ReactHtmlParser from "html-react-parser";
import Navbar from "../../components/Navbar";
import "./BlogPosts.css";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import { Twitter, Facebook, LinkedIn } from "@material-ui/icons";

function BlogPosts(props) {
  const [data, setData] = useState({});
  useEffect(() => {
    getBlogData(props.match.params.blogSuffix).then(res => setData(res))
  });

  const convertDate = (date) => {
    const data = new Date(date) + '';
    const [x,y,z,t] = data.split(" ");
    const fin = z + " " + y + ", " + t
    return fin
  }

  return (
    <>
      <Navbar />
      <div className="custom-container py-3 blog-post-container">
        <div className="">
          <header>
            <h1 className="pb-2">{data?.title}</h1>
            <div className="blog-post-sub">
              <p>
                Swati <span className="blog-post-date">{convertDate(data?.time)}</span>
              </p>
              <div className="blog-post-icons">
                <TwitterShareButton
                  title={`Hey check out this blog <Title> out! Really cool stuff!`}
                  url={"https://testing-demo-portfolio.web.app/"}
                >
                  <Twitter className="bp-icon" />
                </TwitterShareButton>
                <FacebookShareButton
                  quote={`Hey check out this blog <Title> out! Really cool stuff!`}
                  url={"https://testing-demo-portfolio.web.app/"}
                >
                  <Facebook className="bp-icon" />
                </FacebookShareButton>
                <LinkedinShareButton
                  summary={`Hey check out this blog <Title> out! Really cool stuff!`}
                  url={"https://testing-demo-portfolio.web.app/"}
                >
                  <LinkedIn className="bp-icon" />
                </LinkedinShareButton>
              </div>
            </div>
          </header>
        </div>
        <div className="d-flex justify-content-center">
          <img
            className="img-fluid mb-5 blog-post-image"
            style={{
              maxHeight: "400px",
              objectFit: "cover",
              width: "100%",
              maxWidth: "850px",
            }}
            src={data?.imageLink}
            alt=""
          />
        </div>
        <div style={{ wordBreak: "break-word" }}>
          {ReactHtmlParser(data?.blogBody || "")}
        </div>
      </div>
    </>
  );
}

export default BlogPosts;
