import React from "react";
import "./TalkButton.css";

const TalkButton = ({ title, classes, isPlain = false, isSelected, onClick }) => {
  const isActive = window.location.pathname.split('/')[1];
  if(isPlain)
  return (
    <div className={classes} onClick={onClick}>
      <p className={isSelected ? "no-btn-flip no-btn-flip-selected" :"no-btn-flip "} >{title}</p>
    </div>
  );
  return (
    <div className={classes}>
      <p className={`btn-flip ${ isActive=== title.toLowerCase() ? 'nav-tab-active' : ''}`} data-back={title} data-front={title}></p>
    </div>
  );
};

export default TalkButton;
