import React from "react";
import "./ExperienceCard.css";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

const ExperienceCard = ({ title, time, details }) => {
  return (
    <div className="exp col-sm-10 col-xs-12 col-md-11 col-lg-12 mx-auto">
      <div className="exp-inner">
        <WorkOutlineIcon className="exp-icon" style={{fontSize:"60px", marginRight:"2vw", marginTop:"1vh"}}/>
        <div>
          <div className="exp-post">
            <p className="exp-title">{title}</p>
            <p className="exp-time">{time}</p>
          </div>
          <p>{details}</p>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;
