import React, { useEffect, useState } from "react";
import "./Home.css";
import Navbar from "../../components/Navbar";
import HomeImageContainer from "../../components/HomeImageContainer";
import TalkButton from "../../components/TalkButton";
import avatar from "../../assets/avatar.jpg";
import SocialMedia from "../../components/SocialMedia";
import Typewriter from "typewriter-effect";
import homeCardImg from "../../assets/homeCard.svg";
import Feedback from "../../components/Feedback";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onCategoriesClick = (selectedTab) => {
    history.push({
      pathname:"/work",
      state:{
        fromHome: true,
        sortData: true,
        categorySelected: selectedTab
      }
    });
  }

  return (
    <>
      <Navbar />
      <SocialMedia />
      <div className="pageFade">
        <div className="home-card">
          <img className="home-card-vec" src={homeCardImg} alt="" />
          <h3 className="home-desc">
            Hi,
            <strong>
              <span>
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("I am Swati Chakrabarti")
                      .pauseFor(1000)
                      .deleteChars(17)
                      .typeString("a visual storyteller")
                      .start();
                  }}
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>
            </strong>
            I love to work with fonts, play with colours and deal with data to
            make brands look better.
          </h3>
          <img src={avatar} className="home-avatar" alt="avatar" />
        </div>
        <div className="categories">
          <TalkButton
            onClick={() => onCategoriesClick("branding")}
            classes="categories-btn"
            isPlain={true}
            title="Branding"
          />
          <TalkButton
            onClick={() => onCategoriesClick("publications")}
            classes="categories-btn"
            isPlain={true}
            title="Publication"
          />
          <TalkButton
            onClick={() => onCategoriesClick("infographics")}
            classes="categories-btn"
            isPlain={true}
            title="Infographics"
          />
        </div>
        <HomeImageContainer />
        <div onClick={handleClickOpen} className="d-flex justify-content-center pb-3">
          <TalkButton title="Let's Talk" />
        </div>
        <Feedback isOpen={open} handleClose={handleClose} />
      </div>
    </>
  );
};

export default Home;
