import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  HOME_PAGE_PATH,
  BLOG_PAGE_PATH,
  WORK_PAGE_PATH,
  ABOUT_PAGE_PATH,
  ADMIN_LOGIN_PAGE_PATH,
  ADD_UPDATE_DATA,
} from "./constants/Paths";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Work from "./pages/Work";
import About from "./pages/About";
import Login from "./pages/Login";
import BlogPosts from "./pages/BlogPosts";
import AddData from "./pages/AddData";
import Footer from "./components/Footer/Footer";

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path={HOME_PAGE_PATH} component={Home} />
          <Route exact path={BLOG_PAGE_PATH} component={Blog} />
          <Route exact path={WORK_PAGE_PATH} component={Work} />
          <Route exact path={ABOUT_PAGE_PATH} component={About} />
          <Route exact path={ADMIN_LOGIN_PAGE_PATH} component={Login} />
          <Route exact path={ADD_UPDATE_DATA} component={AddData} />
          <Route exact path="/:blogSuffix" component={BlogPosts} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default Routes;
