import React, { useEffect, useState } from "react";
import "./Work.css";
import Categories from "../../components/Categories/Categories";
import CommonCarousel from "../../components/Carousel";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import SocialMedia from "../../components/SocialMedia";
import { CancelRounded } from "@material-ui/icons";
import Navbar from "../../components/Navbar";
import { getPageData } from "../../firebaseConfig";

const MAX_WIDTH = 768;

const Work = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    setDialogOpen(false);
  };
  const [activeSlide, setActiveSlide] = useState(0);
  const [pageData, setPageData] = useState();
  const [activeSlideData, setActiveSlideData] = useState();
  const [category, setCategory] = useState("");
  const [auto, setAuto] = useState(true);
  const [slideLength, setSlideLength] = useState({
    branding: 0,
    publication: 0,
    infographics: 0,
  });
  const [count, setCount] = useState(0);
  const setSlide = (val) => setActiveSlide(val);

  const OnSlide = (index) => {
    setCategory(pageData[index]?.type);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getPageData("work").then((res) => {
      setPageData(res.carousel);
      let homePageData = props.location.state;
      if (homePageData) {
        if (homePageData.sortData) {
          onButtonClick(homePageData.categorySelected, res.carousel);
        } else {
          let index = res.carousel.findIndex(
            (p) =>
              p.title === homePageData.title &&
              p.projectTitle === homePageData.projectTitle
          );
          setSlide(index);
        }
      }
    });
    if (window.innerWidth < MAX_WIDTH) {
      setAuto(false);
    }
  }, []);

  const categoryClick = (data) => {
    let branding = [],
      publication = [],
      inforgraphics = [];
    (pageData || data || []).forEach((item) => {
      if (item.type === "BRANDING") {
        branding.push(item);
      } else if (item.type === "PUBLICATIONS") {
        publication.push(item);
      } else {
        inforgraphics.push(item);
      }
    });
    setPageData([...branding, ...publication, ...inforgraphics]);
    setSlideLength({
      branding: branding.length,
      publication: publication.length,
      infographics: inforgraphics.length,
    });
    return {
      branding: branding.length,
      publication: publication.length,
      infographics: inforgraphics.length,
    };
  };

  const onButtonClick = (slideName, data) => {
    if (categoryClick) {
      if (count === 0) {
        let result = categoryClick(data);
        if (slideName === "branding") {
          setActiveSlide(0);
        } else if (slideName === "publications") {
          setActiveSlide(result.branding);
        } else {
          setActiveSlide(result.branding + result.publication);
        }
        setCount(count + 1);
      } else {
        if (slideName === "branding") {
          setActiveSlide(0);
        } else if (slideName === "publications") {
          setActiveSlide(slideLength.branding);
        } else {
          setActiveSlide(slideLength.branding + slideLength.publication);
        }
      }
    }
  };

  return (
    <>
      <Navbar />
      <SocialMedia />
      <div className="custom-container pageFade">
        <Categories
          setActiveSlide={setSlide}
          category={category}
          categoryClick={categoryClick}
          slideLength={slideLength}
          onButtonClick={onButtonClick}
        />
        <CommonCarousel
          autoPlay={auto}
          selectedItem={activeSlide}
          onChange={OnSlide}
        >
          {pageData
            ? pageData.map((indItem) => {
                return (
                  <div
                    className="row"
                    style={{ padding: "50px 0px", textAlign: "left" }}
                  >
                    <div
                      className="col-md-6 col-sm-12 row"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setActiveSlideData(indItem);
                        setDialogOpen(true);
                      }}
                    >
                      <div className="col-12">
                        <img
                          src={indItem.images[0] ? indItem.images[0] : "#"}
                          className={
                            indItem.type === "INFOGRAPHICS"
                              ? "image-padding work-page-big"
                              : "image-padding work-page-half"
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="col-12"
                        style={
                          indItem.type === "INFOGRAPHICS"
                            ? { display: "none" }
                            : {}
                        }
                      >
                        <div className="row">
                          <div className="col-xs-4 col-md-4 work-images">
                            {indItem.images[1] && (
                              <img
                                src={
                                  indItem.images[1] ? indItem.images[1] : "#"
                                }
                                className="image-padding work-page-small"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="col-xs-4 col-md-4 work-images">
                            {indItem.images[2] && (
                              <img
                                src={
                                  indItem.images[2] ? indItem.images[2] : "#"
                                }
                                className="image-padding work-page-small"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="col-xs-4 col-md-4 work-images">
                            {indItem.images[3] && (
                              <img
                                src={
                                  indItem.images[3] ? indItem.images[3] : "#"
                                }
                                className="image-padding work-page-small"
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-12 work-details">
                      <h1>{indItem.title}</h1>
                      <div className="subtitle">
                        {indItem?.projectName !== "NOTHING" ? (
                          <h4>Project: {indItem?.projectName}</h4>
                        ) : null}
                        {indItem?.clientName !== "NOTHING" ? (
                          <p style={{ marginBottom: "0px" }}>
                            Client: {indItem?.clientName}
                          </p>
                        ) : null}
                      </div>
                      <p>{indItem.desciption}</p>
                    </div>
                  </div>
                );
              })
            : null}
        </CommonCarousel>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        className="work-dialog-conatiner"
        fullScreen={true}
        maxWidth={"xl"}
      >
        {activeSlideData ? (
          <>
            <DialogTitle id="form-dialog-title" className="text-center">
              <div className="dialog-header">
                {activeSlideData?.title}
                <div className="dialog-cancel" onClick={closeDialog}>
                  <CancelRounded />
                </div>
              </div>
            </DialogTitle>
            <DialogContent className="work-dialog-content">
              {activeSlideData.images
                ? activeSlideData.images.map((item) => {
                    return (
                      <div className="d-flex justify-content-center">
                        <img
                          src={item}
                          className="image-padding mx-auto"
                          style={{ maxWidth: "95vw" }}
                          alt=""
                        />
                      </div>
                    );
                  })
                : null}
            </DialogContent>
          </>
        ) : null}
      </Dialog>
    </>
  );
};

export default Work;
