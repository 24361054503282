import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import Routes from "./Routes";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "typeface-poppins";
import "bootstrap/dist/css/bootstrap.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
