import React from "react";
import { Carousel } from "react-responsive-carousel";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { IconButton } from "@material-ui/core";
import "./Carousel.css";

const indicatorStyles = {
  background: "var(--app-red)",
  width: 14,
  height: 2,
  display: "inline-block",
  margin: "0 5px",
};

const indicatorStylesMobile = {
  background: "var(--app-red)",
  width: 6,
  height: 2,
  display: "inline-block",
  margin: "0 2px",
};

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  bottom: "5px",
  width: 30,
  height: 30,
  cursor: "pointer",
  background: "lightgray",
  color: "var(--app-red)",
  borderRadius: "10%",
};

const configIndicator = (onClickHandler, isSelected, index, label) => {
  if (isSelected) {
    return (
      <li
        style={
          window.innerWidth < 768
            ? { ...indicatorStylesMobile, background: "grey" }
            : { ...indicatorStyles, background: "grey" }
        }
      />
    );
  }
  return (
    <li
      style={
        window.innerWidth < 768
          ? { ...indicatorStylesMobile }
          : { ...indicatorStyles }
      }
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      value={index}
      key={index}
      role="button"
      tabIndex={0}
    />
  );
};
console.log(window.innerWidth);
const arrowPrev = (onClickHandler, hasPrev, label) =>
  hasPrev &&
  (window.innerWidth < 768 ? (
    <div
      onClick={onClickHandler}
      style={{
        ...arrowStyles,
        left: "0vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ChevronLeftIcon />
    </div>
  ) : (
    <IconButton
      onClick={onClickHandler}
      style={{ ...arrowStyles, left: "10vw" }}
    >
      <ChevronLeftIcon />
    </IconButton>
  ));

const arrowNext = (onClickHandler, hasNext, label) =>
  hasNext &&
  (window.innerWidth < 768 ? (
    <div
      onClick={onClickHandler}
      style={{
        ...arrowStyles,
        right: "0vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ChevronRightIcon />
    </div>
  ) : (
    <IconButton
      onClick={onClickHandler}
      style={{ ...arrowStyles, right: "10vw" }}
    >
      <ChevronRightIcon />
    </IconButton>
  ));

const CommonCarousel = ({
  children,
  autoPlay = true,
  selectedItem = 0,
  stopOnHover = true,
  onChange,
}) => (
  <Carousel
    autoPlay={autoPlay}
    stopOnHover={stopOnHover}
    showStatus={false}
    showArrows={true}
    showThumbs={false}
    renderIndicator={configIndicator}
    renderArrowPrev={arrowPrev}
    renderArrowNext={arrowNext}
    selectedItem={selectedItem}
    onChange={onChange ? onChange : () => {}}
  >
    {children}
  </Carousel>
);

export default CommonCarousel;
