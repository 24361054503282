import { DeleteOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { deleteBlogPost, getAllBlogData } from "../../firebaseConfig";
import SnackBar from "../../components/SnackBar/SnackBar";

const ListBlogPost = ({ editBlogPost }) => {
  const [blogPostData, setBlogPostData] = useState([]);
  // Snackbar
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    severity: "error",
    message: "Something went wrong",
  });
  const setIsOpenSnackbar = (val) => {
    setShowSnackbar(val);
  };
  // Snackbar Ends
  useEffect(() => {
    (async () => {
      const result = await getAllBlogData();
      setBlogPostData(result);
    })();
  }, []);

  const handleDeleteBlog = async (e, route) => {
    setShowSnackbar(false);
    let response = await deleteBlogPost(route);
    if (response.status === "error") {
      setSnackbarConfig({
        severity: "error",
        message: "Something went wrong!",
      });
    } else {
      setSnackbarConfig({
        severity: "success",
        message: "Deleted!",
      });
      let newBlogPostData = blogPostData.filter((item) => item.route !== route);
      setBlogPostData(newBlogPostData);
    }
    setShowSnackbar(true);
  };

  return blogPostData.map((item, index) => {
    return (
      <>
        <div key={index} className="edit-blog-post">
          <div className="edit-bp-content" onClick={() => editBlogPost(item)}>
            <h3>{item.title}</h3>
            <p>{item.date}</p>
          </div>
          <DeleteOutlined
            className="edit-bin"
            onClick={(e) => handleDeleteBlog(e, item.route)}
          />
        </div>
        <SnackBar
          isOpen={showSnackbar}
          setIsOpen={setIsOpenSnackbar}
          config={snackbarConfig}
        />
      </>
    );
  });
};

export default ListBlogPost;
