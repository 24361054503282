import React, { useEffect, useState } from "react";
import { addPageData, auth, storage } from "../../firebaseConfig";
import { Editor } from "../Editor";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/Loader";
import HomeData from "./HomePage";
import WorkData from "./WorkPage";
import ListBlogPost from "./ListBlogPost";
import BlogPageData from "./BlogPage";
import { sideBarData } from "../../constants/Data";
import "./AddData.css";
import SnackBar from "../../components/SnackBar/SnackBar";

const AddData = () => {
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState("home");
  const [homeImage, setHomeImage] = useState("");
  const [homeDesc, setHomeDesc] = useState("");
  const [user, setUser] = useState("");
  const [editBlogPostData, setEditBlogPostData] = useState({});
  const [workPageData, setWorkPageData] = useState({
    title: "",
    projectName: "",
    clientName: "",
    desciption: "",
    type: "",
    images: [],
    seq: null,
    isChecked: false
  });
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [imageLinks, setImageLinks] = useState([]);
  // Snackbar
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    severity: "error",
    message: "Something went wrong",
  });
  const setIsOpenSnackbar = (val) => {
    setShowSnackbar(val);
  };
  // Snackbar Ends
  const editBlogPost = (item) => {
    setEditBlogPostData({ ...item, edit: true });
    setTabSelected("editBlogPost");
  };

  const handleClick = async (data, pageName, type, setValue, isObj) => {
    if (isObj) {
      setShowSnackbar(false);
      let response = await addPageData(pageName, type, data);
      if (response.status === "error") {
        setSnackbarConfig({
          severity: "error",
          message: "Something went wrong!",
        });
      } else {
        setSnackbarConfig({
          severity: "success",
          message: "Data saved successfully!",
        });
      }
      setShowSnackbar(true);
      setValue({});
    } else {
      setValue("");
    }
  };

  const handleSelectedTab = (data) => {
    setEditBlogPostData({});
    setTabSelected(data);
  };

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      setUser(user);
    });
    if (user) setLoading(false);
  }, [user]);

  const handleChange = (e, setValue, isObj = false, presentValue) => {
    e.preventDefault();
    if (isObj) {
      setValue({ ...presentValue, [e.target.name]: e.target.value });
    } else {
      setValue(e.target.value);
    }
  };

  const handleData = (e) => {
    const val = e.target.value;
    setEditBlogPostData({});
    setTabSelected(val);
  };

  // Image Upload Start
  const handleUpload = (e) => {
    if (e.target.files) {
      const images = e.target.files;
      setImages([...images]);
    }
  };

  const handleImageUpload = (type) => {
    const promises = [];
    for (const image of images) {
      promises.push(uploadImageAsPromise(image));
    }
    Promise.all(promises).then(async (imageURLS) => {
      setImageLinks(imageURLS);
      if (type === "blog") {
        setShowSnackbar(false);
        let response = await addPageData("blog", "images", imageURLS);
        if (response.status === "error") {
          setSnackbarConfig({
            severity: "error",
            message: "Something went wrong!",
          });
        } else {
          setSnackbarConfig({
            severity: "success",
            message: "Data saved successfully!",
          });
        }
        setShowSnackbar(true);
      } else if (type === "work") {
        handleClick(
          { ...workPageData, images: imageURLS },
          "work",
          "carousel",
          setWorkPageData,
          true
        );
      }
      setImageLinks([]);
      setImages([]);
    });
  };

  const uploadImageAsPromise = (image) => {
    return new Promise(function (resolve, reject) {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setSnackbarConfig({
            severity: "warning",
            message: "Uploading Images",
          });
          setShowSnackbar(true);
        },
        (error) => {
          setSnackbarConfig({
            severity: "error",
            message: "Something went wrong!!",
          });
          setShowSnackbar(true);
        },
        async () => {
          setShowSnackbar(false);
          setSnackbarConfig({
            severity: "success",
            message: "Uploaded Image",
          });
          setShowSnackbar(true);
          const imageURL = await uploadTask.snapshot.ref.getDownloadURL();
          resolve(imageURL);
        }
      );
    });
  };
  // Image Upload End

  if (loading)
    return (
      <div style={{ textAlign: "center" }}>
        <h1>
          <Spinner />
        </h1>
      </div>
    );
  return (
    <>
      <div
        className="custom-container"
        style={{ width: "calc(100% - 560px)", marginLeft: "auto" }}
      >
        <div className="componentBase__left">
          <div className="menulist">
            <h3>Dashboard</h3>
            <div className="list">
              {sideBarData.map((item, index) => (
                <button
                  key={index}
                  value={item.name}
                  onClick={handleData}
                  className={
                    tabSelected === item.name
                      ? "addDataButton buttonSelected"
                      : "addDataButton"
                  }
                >
                  {item.title}
                </button>
              ))}
              <button
                value="logout"
                onClick={() => {
                  auth.signOut().then((data) => history.push("/"));
                }}
                className={
                  tabSelected === "logout"
                    ? "addDataButton buttonSelected"
                    : "addDataButton"
                }
              >
                Logout
              </button>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "20px" }}>
          {tabSelected === "home" && (
            <>
              <HomeData
                handleChange={handleChange}
                handleClick={handleClick}
                setHomeImage={setHomeImage}
                homeImage={homeImage}
                homeDesc={homeDesc}
                setHomeDesc={setHomeDesc}
              />
            </>
          )}
          {tabSelected === "work" && (
            <WorkData
              handleChange={handleChange}
              workPageData={workPageData}
              setWorkPageData={setWorkPageData}
              handleUpload={handleUpload}
              handleImageUpload={handleImageUpload}
            />
          )}
          {tabSelected === "blog" && (
            <BlogPageData
              handleUpload={handleUpload}
              handleImageUpload={handleImageUpload}
              imageLinks={imageLinks}
              setImages={setImages}
              setImageLinks={setImageLinks}
            />
          )}
          {tabSelected === "blogPost" && (
            <Editor
              prefilledData={editBlogPostData}
              handleSelectedTab={handleSelectedTab}
            />
          )}
          {tabSelected === "listBlogPost" && (
            <ListBlogPost editBlogPost={editBlogPost} />
          )}
          {tabSelected === "editBlogPost" && (
            <Editor
              prefilledData={editBlogPostData}
              handleSelectedTab={handleSelectedTab}
            />
          )}
        </div>
      </div>
      <SnackBar
        isOpen={showSnackbar}
        setIsOpen={setIsOpenSnackbar}
        config={snackbarConfig}
      />
    </>
  );
};

export default AddData;
