import React, { useEffect, useState } from "react";
import "./Blog.css";
import CommonCarousel from "../../components/Carousel";
import SocialMedia from "../../components/SocialMedia";
import Navbar from "../../components/Navbar";
import vectorRight from "../../assets/vector-right.svg";
import vectorLeft from "../../assets/vector-left.svg";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { getAllBlogData, getPageData } from "../../firebaseConfig";
import { Link } from "react-router-dom";

const groupDataAsPerSlide = (arr, val) => {
  var chunks = [];
  var count = 0;
  while (count + val < arr?.length) {
    chunks.push(arr.slice(count, count + val));
    count += val;
  }
  if (count <= arr?.length) chunks.push(arr.slice(count));
  return chunks;
};

const Blog = () => {
  const [blogsCarouselCount, setBlogsCarouselCount] = useState(3);
  const [instagramCarouselCount, setInstagramCarouselCount] = useState(4);
  const [latestBlogsData, setLatestBlogsData] = useState([]);
  const [instagramPosts, setInstagramPosts] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.screen.width < 768) {
      setBlogsCarouselCount(1);
      setInstagramCarouselCount(1);
    }
    getPageData("blog").then((res) => setInstagramPosts(res.images));
    getAllBlogData().then((res) => setLatestBlogsData(res));
  }, []);

  const convertDate = (date) => {
    const data = new Date(date) + "";
    const [x, y, z, t] = data.split(" ");
    const fin = z + " " + y + ", " + t;
    return fin;
  };

  const RenderBlogPost = ({ item }) => {
    return (
      <div
        className="col-md-4 col-sm-12 blog-card"
        style={{ cursor: "pointer" }}
      >
        <img
          src={item.imageLink}
          className="w-100 image-padding"
          alt=""
          style={{ height: "55vh", objectFit: "cover", marginBottom: "10px" }}
        />
        <Link to={item.route} style={{ textDecoration: "none" }}>
          <h2 style={{ textDecoration: "none", color: "black" }}>
            {item.title}
          </h2>
          <p style={{ textDecoration: "none", color: "black" }}>
            {convertDate(item.time)}
          </p>
        </Link>
      </div>
    );
  };
  return (
    <>
      <Navbar />
      <SocialMedia />
      <div className="blog-container pageFade">
        <h2 className="text-center">Blogs</h2>
        <div className="d-flex">
          <img className="blog-vec-left" src={vectorLeft} alt="vector" />
          <div className="blog-text">
            <p className="blog-text-p">
              I love travelling, mostly off the grid.
            </p>
            <p>
              And I prefer to travel like a local, to get a closer look at the
              land and its people. For more on my travel experiments you can
              read my blog. When am not travelling I do research on destinations
              for my friends and family.
            </p>
            <p className="blog-text-p">Feel free to ask.</p>
          </div>
          <img className="blog-vec-right" src={vectorRight} alt="vector" />
        </div>
        {/* Blog Card Begins */}
        {latestBlogsData[0] && (
          <>
            <div className="blog-highlight">
              <Link to={`/${latestBlogsData[0]?.route}`}>
                <img
                  className="bh-img"
                  src={latestBlogsData[0]?.imageLink}
                  alt="blog"
                />
                <p className="bh-heading">{latestBlogsData[0]?.title}</p>
                <div className="blog-highlight-overlay">
                  <CalendarTodayIcon fontSize="small" />
                  <p>{convertDate(latestBlogsData[0]?.time)}</p>
                </div>
              </Link>
            </div>
            <CommonCarousel autoPlay={false}>
              {groupDataAsPerSlide(
                latestBlogsData.slice(1),
                blogsCarouselCount
              ).map((item) => {
                return (
                  <div className="row my-4">
                    {item.map((indItem) => (
                      <RenderBlogPost item={indItem} />
                    ))}
                  </div>
                );
              })}
            </CommonCarousel>
          </>
        )}

        {instagramPosts && <h2 className="text-center my-3">INSTAGRAM</h2>}
        <div className="row">
          <CommonCarousel>
            {groupDataAsPerSlide(instagramPosts, instagramCarouselCount).map(
              (item) => {
                return (
                  <div className="row mb-5">
                    {item.map((indItem) => (
                      <div className=" col-md-3 col-lg-3 col-sm-6 col-xs-12 mx-auto">
                        <img
                          src={indItem}
                          alt=""
                          className="image-padding insta-images"
                        />
                      </div>
                    ))}
                  </div>
                );
              }
            )}
          </CommonCarousel>
        </div>
      </div>
    </>
  );
};

export default Blog;
