import React from "react";

const BlogPage = ({ handleUpload, handleImageUpload }) => {
  return (
    <>
      <input type="file" onChange={handleUpload} multiple />
      <button
        className="addDataButton"
        onClick={() => handleImageUpload("blog")}
      >
        Upload
      </button>
    </>
  );
};

export default BlogPage;
