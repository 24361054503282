import React from "react";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const WorkPage = ({
  handleChange,
  workPageData,
  setWorkPageData,
  handleUpload,
  handleImageUpload,
}) => {
  return (
    <div>
      <input type="file" onChange={handleUpload} multiple />
      <input
        name="title"
        type="string"
        placeholder="Title"
        required
        value={workPageData.title || ""}
        onChange={(e) => handleChange(e, setWorkPageData, true, workPageData)}
        className="form-control text-center addDataInput"
      />
      <input
        name="projectName"
        type="string"
        placeholder="Project Name"
        value={workPageData.projectName || ""}
        required
        onChange={(e) => handleChange(e, setWorkPageData, true, workPageData)}
        className="form-control text-center addDataInput"
      />
      <input
        name="clientName"
        type="string"
        placeholder="Client Name "
        required
        value={workPageData.clientName || ""}
        onChange={(e) => handleChange(e, setWorkPageData, true, workPageData)}
        className="form-control text-center addDataInput"
      />
      <textarea
        name="desciption"
        type="string"
        placeholder="Add Desciption"
        required
        rows="10"
        value={workPageData.desciption || ""}
        onChange={(e) => handleChange(e, setWorkPageData, true, workPageData)}
        className="form-control text-center addDataInput"
      />
      <Select
          name="type"
          value={workPageData.type || ""}
          onChange={(e) => handleChange(e, setWorkPageData, true, workPageData)}
          className="form-control text-center addDataInput"
        >
          <MenuItem value={"PUBLICATIONS"}>Publications</MenuItem>
          <MenuItem value={"BRANDING"}>Branding</MenuItem>
          <MenuItem value={"INFOGRAPHICS"}>Infographics</MenuItem>
        </Select>
        <br />
      <button
        onClick={() => handleImageUpload("work")}
        className={
          !workPageData.title ||
          !workPageData.projectName ||
          !workPageData.desciption ||
          !workPageData.type ||
          !workPageData.clientName
            ? "addButtonDataDisabled"
            : "addDataButton"
        }
        disabled={
          !workPageData.title ||
          !workPageData.projectName ||
          !workPageData.desciption ||
          !workPageData.type ||
          !workPageData.clientName
        }
      >
        Save
      </button>
    </div>
  );
};
export default WorkPage;
