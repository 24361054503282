import React, { useState } from "react";
import "./Style.css";
import { useHistory } from "react-router-dom";
import { auth } from "../../firebaseConfig";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const history = useHistory();
  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };
  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then((data) => history.push("/admin/update"))
      .catch((error) => {
        setError("Invalid Email or Password!");
      });
  };

  return (
    <div className="login-container">
      <h1 className="login-header">Sign In</h1>
        <form className="login-form">
          <label htmlFor="userEmail" className="login-block">
            Email:
          </label>
          <input
            type="email"
            className="input-email"
            name="userEmail"
            value={email}
            placeholder="Enter your email"
            id="userEmail"
            onChange={(event) => onChangeHandler(event)}
          />
          <br />
          <label htmlFor="userPassword" className="login-block">
            Password:
          </label>
          <input
            type="password"
            className="input-password"
            name="userPassword"
            value={password}
            placeholder="Enter your password"
            id="userPassword"
            onChange={(event) => onChangeHandler(event)}
          />
          {error !== null && (
            <div className="login-error">&#10007; {error}</div>
          )}
          <button
            className="login-button"
            onClick={(event) => {
              signInWithEmailAndPasswordHandler(event, email, password);
            }}
          >
            Sign in
          </button>
        </form>
      </div>
  );
};
export default Login;
