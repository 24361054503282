import React, { useEffect, useState } from "react";
import "./About.css";
import SkillsCard from "../../components/SkillsCard";
import ExperienceCard from "../../components/ExperienceCard";
import TalkButton from "../../components/TalkButton";
import { getPageData } from "../../firebaseConfig";
import ClientCard from "../../components/ClientCard";
import SocialMedia from "../../components/SocialMedia";
import Navbar from "../../components/Navbar";
import Feedback from "../../components/Feedback";
import PageArrow from "../../components/PageArrow";
import {feedback,data, experience} from "./about.json"

const About = () => {
  const [peopleSay, setPeopleSay] = useState([]);
  const [open, setOpen] = useState(false);

  const [downArrowLink, setDownArrowLink] = useState("client");
  const [reachedEnd, setReachedEnd] = useState(false);
  const [heights, setHeights] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageData("about").then((res) => {
      setPeopleSay(res?.carousel);
    });
    getIdElementsHeight();
  }, []);

  const getIdElementsHeight = () => {
    let clientHeight = document
      .getElementById("client")
      .getBoundingClientRect();
    let experienceHeight = document
      .getElementById("experience")
      .getBoundingClientRect();
    let talkHeight = document.getElementById("talk").getBoundingClientRect();
    let totalPageHeight = document.documentElement.offsetHeight;
    let pageHeight = window.innerHeight;
    setHeights({
      clientHeight: clientHeight.top,
      experienceHeight: experienceHeight.top,
      talkHeight: talkHeight.top,
      lastPageHeight: totalPageHeight - pageHeight,
    });
  };

  const scrollTracker = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled >= heights.lastPageHeight - 10) {
      setReachedEnd(true);
      setDownArrowLink("about");
    } else {
      setReachedEnd(false);
      if (scrolled < heights.clientHeight) setDownArrowLink("client");
      if (
        scrolled >= heights.clientHeight &&
        scrolled < heights.experienceHeight
      )
        setDownArrowLink("experience");
      if (scrolled >= heights.experienceHeight && scrolled < heights.talkHeight)
        setDownArrowLink("talk");
    }
  };

  window.addEventListener("scroll", scrollTracker);

  return (
    <>
      <Navbar isTransparent={true} />
      <SocialMedia />
      <PageArrow
        orderList={["client", "experience", "talk"]}
        orderNo={0}
        top="about"
        linkTo={downArrowLink}
        hasReachedEnd={reachedEnd}
      />
      <div className="about-banner pageFade" id="about">
        <div className="about-banner-text">
          <h3 className="text-right">
            <strong>
              Hi, I am a graphic designer and an infographic specialist.
            </strong>
          </h3>
          <p className="text-right">
            Having a background of science education in school coupled with Fine
            Arts Degree in college, I have had the inclination towards design
            which binds logic and aesthetics together. After 15 years of
            experience in the print media I moved on to custom publishing and
            now venture in to brand design and development, publication design
            and social media design as well.
          </p>
        </div>
      </div>
      <div className="about custom-container">
        <div className="feedback-section" id="client">
          <p className="ame-title pt-5">What Clients Say</p>
          <div className="am-client row">
            {feedback?.map((item,index) => {
              return (
                <ClientCard
                  key={index}
                  name={item.name}
                  feedback={item.description}
                  designation={item.designation}
                />
              );
            })}
          </div>
        </div>
        <div className="am-experience">
        <p className="ame-title" id="skills">
            Skills and Expertise
          </p>
        <div className="skills row">
          {data.map((item) => (
            <SkillsCard key={item.id} name={item.name} desc={item.desc} />
          ))}
        </div>
        </div>
        <div className="am-experience">
          <p className="ame-title" id="experience">
            My Previous Experience
          </p>
          <div className="row">
            {experience.map((item) => (
              <ExperienceCard
                key={item.id}
                title={item.title}
                time={item.time}
                details={item.details}
              />
            ))}
          </div>
        </div>
        <div
          className=""
          style={{
            height: "1px",
            width: "80vw",
            backgroundColor: "#000000",
            margin: "auto",
          }}
        ></div>
        <div onClick={handleClickOpen} className="button pb-5" id="talk">
          <TalkButton title="Let's Talk" />
        </div>
        <Feedback isOpen={open} handleClose={handleClose} />
      </div>
    </>
  );
};

export default About;
