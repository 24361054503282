import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SnackBar({ isOpen, setIsOpen, config, time = 5000 }) {
  const classes = useStyles();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={isOpen} autoHideDuration={time} onClose={handleClose}>
        <Alert onClose={handleClose} severity={config.severity}>
          {config.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
