import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowBackIos";
import "./PageArrow.css";

function PageArrow({ linkTo, hasReachedEnd }) {
  return (
    <div className="icon-container bounce">
      <a href={`#${linkTo}`}>
        <ArrowForwardIosIcon
          style={{ fontSize: 40 }}
          className={!hasReachedEnd ? "arrow-up" : "arrow-down"}
        />
      </a>
    </div>
  );
}

export default PageArrow;
