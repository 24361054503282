import React from "react";
import "./Footer.css";
import {
  Twitter,
  Facebook,
  LinkedIn,
  Instagram,
  BorderColor
} from "@material-ui/icons";

const Footer = () => {
  return (
    <div className="footer">
      <div
        className="f-line"
        style={{
          height: "1px",
          width: "83vw",
          backgroundColor: "var(--text-grey)",
          margin: "auto",
        }}
      ></div>
      <div className="f-icons">
      <a href="https://twitter.com/swatchat" data-toggle="tooltip" data-placement="top" title="Twitter">
        <Twitter className="footer-icon" />
      </a>
      <a href="https://www.facebook.com/CforSwati" data-toggle="tooltip" data-placement="top" title="Facebook">
        <Facebook className="footer-icon" />
      </a>
      <a href="https://www.linkedin.com/in/swati-chakrabarti-b176802/" data-toggle="tooltip" data-placement="top" title="LinkedIn">
        <LinkedIn className="footer-icon" />
      </a>
      <a href="https://www.instagram.com/cforswati/" data-toggle="tooltip" data-placement="top" title="Instagram">
        <Instagram className="footer-icon" />
      </a>{" "}
      <a href="https://journeysbylane.in/" data-toggle="tooltip" data-placement="top" title="Blog">
        <BorderColor className="footer-icon" />
      </a>
      </div>
    </div>
  );
};

export default Footer;
