export const sideBarData = [
  {
    name: "home",
    title: "Home Page",
  },
  {
    name: "blog",
    title: "Insta Image",
  },
  {
    name: "work",
    title: "Work Page",
  },
  {
    name: "blogPost",
    title: "Add Blog Post",
  },
  {
    name: "listBlogPost",
    title: "List Blog Post",
  },
];
