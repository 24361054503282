import React, { useState } from "react";
import JoditEditor from "jodit-react";
import "./Editor.css";
import { useEffect } from "react";
import { postBlogData, storage } from "../../firebaseConfig";
import SnackBar from "../../components/SnackBar/SnackBar";

const joditConfig = {
  readonly: false,
  height: "600",
};

export const Editor = ({ prefilledData, handleSelectedTab }) => {
  const [content, setContent] = useState("");
  const [route, setroute] = useState("");
  const [title, setTitle] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [image, setImage] = useState("");
  const [edit, setEdit] = useState(false);
  // Snackbar
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    severity: "error",
    message: "Something went wrong",
  });
  const setIsOpenSnackbar = (val) => {
    setShowSnackbar(val);
  };
  // Snackbar Ends
  useEffect(() => {
    if (prefilledData.edit) setEdit(true);
    if (prefilledData.edit) {
      if (prefilledData.route) setroute(prefilledData.route);
      if (prefilledData.title) setTitle(prefilledData.title);
      if (prefilledData.edit) setEdit(true);
      if (prefilledData.blogBody) setContent(prefilledData.blogBody);
      if (prefilledData.imageLink) setImageLink(prefilledData.imageLink);
    }
  }, []);

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === "route") {
      setroute(value);
    } else if (name === "imageLink") {
      setImageLink(value);
    }
    if (name === "blogTitle") {
      setTitle(value);
    }
  };

  const publishPost = async () => {
    let response = await postBlogData(title, route, imageLink, content);
    setShowSnackbar(false);
    if (response.status === "error") {
      setSnackbarConfig({
        severity: "error",
        message: "Something went wrong!",
      });
    } else {
      setSnackbarConfig({
        severity: "success",
        message: "Blog published!",
      });
      handleSelectedTab("listBlogPost");
    }
    setShowSnackbar(true);
  };

  const uploadImage = () => {
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setShowSnackbar(false);
        setSnackbarConfig({
          severity: "warning",
          message: "Uploading image",
        });
        setShowSnackbar(true);
      },
      (error) => {
        setShowSnackbar(false);
        setSnackbarConfig({
          severity: "error",
          message: "Something went wrong! Please try again!",
        });
        setShowSnackbar(true);
      },
      async () => {
        await uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setImageLink(url);
          setShowSnackbar(false);
          setSnackbarConfig({
            severity: "success",
            message: "Image uploaded!",
          });
          setShowSnackbar(true);
        });
      }
    );
  };

  const handleImageUpload = (e) => {
    if (e.target.files) {
      const image = e.target.files[0];
      setImage(image);
    }
  };

  return (
    <div className="custom-container" style={{ maxWidth: "100%" }}>
      <label htmlFor="route" className="link-block">
        Blog Link Route:
      </label>
      <input
        type="text"
        className="input-link addDataInput"
        name="route"
        value={route}
        placeholder="Enter your Link Route for this blog https://xyz.com/<Route>"
        id="input-link"
        onChange={onChangeHandler}
      />
      <br />
      <label htmlFor="blogTitle" className="link-block">
        Blog Title:
      </label>
      <input
        type="text"
        className="input-link addDataInput"
        name="blogTitle"
        value={title}
        placeholder="Enter your Blog Title"
        id="input-link"
        onChange={onChangeHandler}
      />
      <br />
      <input type="file" onChange={handleImageUpload} />
      <button className="addDataButton" onClick={uploadImage}>
        Upload
      </button>
      <JoditEditor
        value={content}
        config={joditConfig}
        tabIndex={1}
        onBlur={(newContent) => setContent(newContent)}
      />
      <button
        disabled={!imageLink || !route || !content || !title}
        onClick={publishPost}
        className={
          !imageLink || !route || !content || !title
            ? "addButtonDataDisabled"
            : "addDataButton"
        }
      >
        Publish
      </button>
      <SnackBar
        isOpen={showSnackbar}
        setIsOpen={setIsOpenSnackbar}
        config={snackbarConfig}
      />
    </div>
  );
};
