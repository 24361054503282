import React from "react";
import TalkButton from "../TalkButton";
import "./Categories.css";

const Categories = ({
  setActiveSlide,
  slideLength,
  category = "",
  categoryClick,
  onButtonClick
}) => {
  return (
    <div className="categories">
      <TalkButton
        onClick={() => onButtonClick("branding")}
        isSelected={category === "BRANDING" ? true : false}
        classes="categories-btn"
        isPlain={true}
        title="Branding"
      />
      <TalkButton
        onClick={() => onButtonClick("publications")}
        isSelected={category === "PUBLICATIONS" ? true : false}
        classes="categories-btn"
        isPlain={true}
        title="Publication"
      />
      <TalkButton
        onClick={() => onButtonClick("inforgraphics")}
        isSelected={category === "INFOGRAPHICS" ? true : false}
        classes="categories-btn"
        isPlain={true}
        title="Infographics"
      />
    </div>
  );
};

export default Categories;
