import React, { useEffect, useState } from "react";
import "./HomeImageContainer.css";
import { Link } from "react-router-dom";
import { getPageData } from "../../firebaseConfig";
import LazyLoadImage from "../LazyLoadImage";

const HomeImageContainer = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalFive, setTotalFive] = useState(Math.floor(total / 5));
  const [totalFiveIncInverted, setTotalFiveIncInverted] = useState(
    Math.floor(totalFive / 2)
  );
  const [, setRemainder] = useState(total % 5);
  const [, setIsExtraFive] = useState(totalFive % 2);

  useEffect(() => {
    (async () => {
      let response = await getPageData("home");
      let homePageData = response.carousel;
      setData(homePageData);
      setTotal(homePageData.length);
      setTotalFive(Math.floor(homePageData.length / 5));
      setTotalFiveIncInverted(
        Math.floor(Math.floor(homePageData.length / 5) / 2)
      );
      setRemainder(homePageData.length % 5);
      setIsExtraFive(Math.floor(homePageData.length / 5) % 2);
    })();
  }, []);

  return (
    <div className="home-image-container custom-container">
      {[...Array(totalFiveIncInverted)].map((item, index) => {
        return (
          <div key={index}>
            <Five img={data.slice(index * 10, index * 10 + 5)} />
            <FiveInverted img={data.slice(index * 10 + 5, index * 10 + 10)} />
          </div>
        );
      })}
      {/* {isExtraFive === 1 && (
        <Five img={data.slice((totalFive - 1) * 5, totalFive * 5)} />
      )}
      {remainder === 1 && <One img={data.slice(-remainder)} />}
      {remainder === 2 && <Two img={data.slice(-remainder)} />}
      {remainder === 3 && <Three img={data.slice(-remainder)} />}
      {remainder === 4 && <Four img={data.slice(-remainder)} />} */}
    </div>
  );
};
const Five = ({ img }) => (
  <div className="row">
    <div className="col-md-6 col-sm-12 col-xs-12">
      <div className="row">
        <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[0].title, projectName: img[0].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
          <div className="img_container">
            <LazyLoadImage image={{src:`${img[0]?.images[0]}` , alt:"img1"}} className="big-img img-fluid image-padding home-image-small" />
            <div className="overlay">
              <p className="image_desc">{img[0]?.projectName}</p>
            </div>
          </div>
        </Link>
        <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[1].title, projectName: img[1].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
          <div className="img_container">
            <LazyLoadImage image={{src:`${img[1]?.images[0]}` , alt:"img2"}} className="big-img img-fluid image-padding home-image-small" />
            <div className="overlay">
              <p className="image_desc"> {img[1]?.projectName}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="row">
        <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[2].title, projectName: img[2].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
          <div className="img_container">
            <LazyLoadImage image={{src:`${img[2]?.images[0]}` , alt:"img3"}} className="big-img img-fluid image-padding home-image-small" />
            <div className="overlay">
              <p className="image_desc"> {img[2]?.projectName}</p>
            </div>
          </div>
        </Link>
        <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[3].title, projectName: img[3].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
          <div className="img_container">
            <LazyLoadImage image={{src:`${img[3]?.images[0]}` , alt:"img4"}} className="big-img img-fluid image-padding home-image-small" />
            <div className="overlay">
              <p className="image_desc">{img[3]?.projectName}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
    <div className="col-md-6 col-sm-12 col-xs-12">
      <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[4].title, projectName: img[4].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
        <div className="img_container">
          <LazyLoadImage image={{src:`${img[4]?.images[0]}` , alt:"img5"}} className="big-img img-fluid image-padding home-image-big" />
          <div className="overlay">
            <p className="image_desc">{img[4]?.projectName}</p>
          </div>
        </div>
      </Link>
    </div>
  </div>
);
const FiveInverted = ({ img }) => (
  <div className="row">
    <div className="col-md-6 col-sm-12 col-xs-12">
      <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[0].title, projectName: img[0].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
        <div className="img_container">
          <LazyLoadImage image={{src:`${img[0]?.images[0]}` , alt:"img6"}} className="big-img img-fluid image-padding home-image-big" />
          <div className="overlay">
            <p className="image_desc">{img[0]?.projectName}</p>
          </div>
        </div>
      </Link>
    </div>
    <div className="col-md-6 col-sm-12 col-xs-12">
      <div className="row">
        <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[1].title, projectName: img[1].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
          <div className="img_container">
            <LazyLoadImage image={{src:`${img[1]?.images[0]}` , alt:"img7"}} className="big-img img-fluid image-padding home-image-small" />
            <div className="overlay">
              <p className="image_desc">{img[1]?.projectName}</p>
            </div>
          </div>
        </Link>
        <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[2].title, projectName: img[2].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
          <div className="img_container">
            <LazyLoadImage image={{src:`${img[2]?.images[0]}` , alt:"img8"}} className="big-img img-fluid image-padding home-image-small" />
            <div className="overlay">
              <p className="image_desc">{img[2]?.projectName}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="row">
        <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[3].title, projectName: img[3].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
          <div className="img_container">
            <LazyLoadImage image={{src:`${img[3]?.images[0]}` , alt:"img9"}} className="big-img img-fluid image-padding home-image-small" />
            <div className="overlay">
              <p className="image_desc">{img[3]?.projectName}</p>
            </div>
          </div>
        </Link>
        <Link to={{ pathname: '/work', state: { fromHome: true, sortData: false, title: img[4].title, projectName: img[4].projectName }}} className=" col-md-6 col-sm-6 col-xs-12">
          <div className="img_container">
            <LazyLoadImage image={{src:`${img[4]?.images[0]}` , alt:"img10"}} className="big-img img-fluid image-padding home-image-small" />
            <div className="overlay">
              <p className="image_desc">{img[4]?.projectName}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
);
// const Four = ({ img }) => (
//   <div className="row">
//     <div className="col-md-12 col-sm-12 col-xs-12">
//       <div className="row">
//         <Link to="/work" className=" col-md-6 col-sm-6 col-xs-12">
//           <div className="img_container">
//             <img
//               src={img[0]?.images[0]}
//               className="img-fluid image-padding image_hover"
//               alt="home"
//             />
//             <div className="overlay">
//               <p className="image_desc">{img[0]?.projectName}</p>
//             </div>
//           </div>
//         </Link>
//         <Link to="/work" className=" col-md-6 col-sm-6 col-xs-12">
//           <div className="img_container">
//             <img
//               src={img[1]?.images[0]}
//               className="img-fluid image-padding image_hover"
//               alt="home"
//             />
//             <div className="overlay">
//               <p className="image_desc">{img[1]?.projectName}</p>
//             </div>
//           </div>
//         </Link>
//       </div>
//       <div className="row">
//         <Link to="/work" className=" col-md-6 col-sm-6 col-xs-12">
//           <div className="img_container">
//             <img
//               src={img[2]?.images[0]}
//               className="img-fluid image-padding image_hover"
//               alt="home"
//             />
//             <div className="overlay">
//               <p className="image_desc">{img[2]?.projectName}</p>
//             </div>
//           </div>
//         </Link>
//         <Link to="/work" className=" col-md-6 col-sm-6 col-xs-12">
//           <div className="img_container">
//             <img
//               src={img[3]?.images[0]}
//               className="img-fluid image-padding image_hover"
//               alt="home"
//             />
//             <div className="overlay">
//               <p className="image_desc">{img[3]?.projectName}</p>
//             </div>
//           </div>
//         </Link>
//       </div>
//     </div>
//   </div>
// );
// const Three = ({ img }) => (
//   <div className="row">
//     <Link to="/work" className="col-md-4 col-sm-4 col-xs-12">
//       <div className="img_container">
//         <img
//           src={img[0]?.images[0]}
//           className="img-fluid image-padding image_hover"
//           alt="home"
//         />
//         <div className="overlay">
//           <p className="image_desc">{img[0]?.projectName}</p>
//         </div>
//       </div>
//     </Link>
//     <Link to="/work" className="col-md-4 col-sm-4 col-xs-12">
//       <div className="img_container">
//         <img
//           src={img[1]?.images[0]}
//           className="img-fluid image-padding image_hover"
//           alt="home"
//         />
//         <div className="overlay">
//           <p className="image_desc">{img[1]?.projectName}</p>
//         </div>
//       </div>
//     </Link>
//     <Link to="/work" className="col-md-4 col-sm-4 col-xs-12">
//       <div className="img_container">
//         <img
//           src={img[2]?.images[0]}
//           className="img-fluid image-padding image_hover"
//           alt="home"
//         />
//         <div className="overlay">
//           <p className="image_desc">{img[2]?.projectName}</p>
//         </div>
//       </div>
//     </Link>
//   </div>
// );
// const Two = ({ img }) => (
//   <div className="row">
//     <Link to="/work" className="col-md-6 col-sm-6 col-xs-12">
//       <div className="img_container">
//         <img
//           src={img[0]?.images[0]}
//           className="img-fluid image-padding image_hover"
//           alt="home"
//         />
//         <div className="overlay">
//           <p className="image_desc">{img[0]?.projectName}</p>
//         </div>
//       </div>
//     </Link>
//     <Link to="/work" className="col-md-6 col-sm-6 col-xs-12">
//       <div className="img_container">
//         <img
//           src={img[1]?.images[0]}
//           className="img-fluid image-padding image_hover"
//           alt="home"
//         />
//         <div className="overlay">
//           <p className="image_desc">{img[1]?.projectName}</p>
//         </div>
//       </div>
//     </Link>
//   </div>
// );
// const One = ({ img }) => (
//   <>
//     <Link to="/work" className="col-md-12 col-sm-12 col-xs-12">
//       <div className="img_container">
//         <img
//           src={img[0]?.images[0]}
//           className="img-fluid image-padding image_hover"
//           alt="home"
//         />
//         <div className="overlay">
//           <p className="image_desc">{img[0]?.projectName}</p>
//         </div>
//       </div>
//     </Link>
//   </>
// );
export default HomeImageContainer;
