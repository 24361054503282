import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import SnackBar from "../SnackBar/SnackBar";

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
}

const Feedback = ({ isOpen = false, handleClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");

  // Snackbar
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    severity: "error",
    message: "Something went wrong",
  });
  const setIsOpenSnackbar = (val) => {
    setShowSnackbar(val);
  };
  // Snackbar Ends
  const handleSubmitFormData = () => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios
      .post(`https://formsubmit.co/ajax/swatchak@gmail.com`, {
        name: name,
        email: email,
        message: desc,
      })
      .then((response) => {
        setName("");
        setEmail("");
        setDesc("");
        setIsOpenSnackbar(false);
        setSnackbarConfig({
          severity: "success",
          message: "Message sent successfully!",
        });
        setIsOpenSnackbar(true);
      })
      .catch((error) => {
        setIsOpenSnackbar(false);
        setSnackbarConfig({
          severity: "error",
          message: "Something went wrong!",
        });
        setIsOpenSnackbar(true);
      });
    handleClose();
  };

  

  const handleChange = (e) => {
    if (e.target.name === "name") setName(e.target.value);
    if (e.target.name === "email") setEmail(e.target.value);
    if (e.target.name === "message") setDesc(e.target.value);
  };
  return (
    <div>
      <form
        action="https://formsubmit.co/anjali.bs.dev@gmail.com"
        method="POST"
      >
        <Dialog
          TransitionComponent={Transition}
          keepMounteds
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Contact Me</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              value={name}
              name="name"
              onChange={handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Email Address"
              value={email}
              name="email"
              onChange={handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Message"
              value={desc}
              name="message"
              onChange={handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitFormData} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <SnackBar
        isOpen={showSnackbar}
        setIsOpen={setIsOpenSnackbar}
        config={snackbarConfig}
      />
    </div>
  );
};

export default Feedback;
