import React from "react";
import {
  Twitter,
  Facebook,
  LinkedIn,
  Instagram,
  BorderColor,
} from "@material-ui/icons";
//import BorderColorIcon from '@mui/icons-material/BorderColor';
import "./SocialMedia.css";
function SocialMedia() {
  return (
    <section className="social-media-container">
      <a href="https://twitter.com/swatchat" data-toggle="tooltip" data-placement="right" title="Twitter">
        <Twitter className="social-media-icons" />
      </a>
      <a href="https://www.facebook.com/CforSwati" data-toggle="tooltip" data-placement="right" title="Facebook">
        <Facebook className="social-media-icons" />
      </a>
      <a href="https://www.linkedin.com/in/swati-chakrabarti-b176802/" data-toggle="tooltip" data-placement="right" title="LinkedIn">
        <LinkedIn className="social-media-icons" />
      </a>
      <a href="https://www.instagram.com/cforswati/" data-toggle="tooltip" data-placement="right" title="Instagram">
        <Instagram className="social-media-icons" />
      </a>{" "}
      <a href="https://journeysbylane.in/" data-toggle="tooltip" data-placement="right" title="Blog">
        <BorderColor className="social-media-icons" />
      </a>
    </section>
  );
}

export default SocialMedia;
