import React, { useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { MenuItem, Select } from "@material-ui/core";
import { addPageData, getPageData } from "../../firebaseConfig";
import SnackBar from "../../components/SnackBar/SnackBar";
const totalArray = [null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const HomePage = () => {
  const [data, setData] = useState([]);

  // Snackbar
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    severity: "error",
    message: "Something went wrong",
  });
  const setIsOpenSnackbar = (val) => {
    setShowSnackbar(val);
  };
  // Snackbar Ends

  useEffect(() => {
    getPageData("work").then((resWork) => {
      getPageData("home").then((resHome) => {
        let seq = [];
        let cnt = 0;
        let updatedData = resWork.carousel.map((indItem) => {
          let data = resHome?.carousel?.filter(
            (temp) =>
              temp.projectName === indItem.projectName &&
              temp.clientName === indItem.clientName
          );
          if (data && data[0]) {
            cnt++;
            seq.push(data[0]?.seq);
            return {
              ...indItem,
              seq: data[0]?.seq,
              isChecked: data[0]?.isChecked,
            };
          } else return indItem;
        });
        setData(updatedData);
        setSeqNoUsed(seq);
        setCount(cnt);
      });
    });
  }, []);

  const submitData = async () => {
    let selectedData = data.filter((item) => item.isChecked === true);
    selectedData.sort(function (a, b) {
      return a.seq - b.seq;
    });
    let response = await addPageData("home", "carouselV2", selectedData);
    setShowSnackbar(false);
    if (response.status === "error") {
      setSnackbarConfig({
        severity: "error",
        message: "Something went wrong!",
      });
    } else {
      setSnackbarConfig({
        severity: "success",
        message: "Seq Updated Successfully!",
      });
    }
    setShowSnackbar(true);
  };

  const [count, setCount] = useState(0); // to be initialised on api call
  const [seqNoUsed, setSeqNoUsed] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const findIndex = (item) =>
    data.findIndex((indData) => indData.title === item.title);

  const handleChange = (e, item) => {
    let newData = data;
    let itemIndex = findIndex(item);
    if (item.isChecked) {
      newData[itemIndex].isChecked = false;
      let newSeqNo = seqNoUsed.filter((no) => no !== newData[itemIndex].seq);
      setSeqNoUsed(newSeqNo);
      newData[itemIndex].seq = null;

      setCount(count - 1);
    } else {
      if (count >= 10) {
        setShowSnackbar(false);
        setSnackbarConfig({
          severity: "error",
          message: "Can't select more than 10 items",
        });
        setShowSnackbar(true);
      } else {
        newData[itemIndex].isChecked = true;
        setCount(count + 1);
      }
    }
    setData(newData);
  };

  const handleSequence = (e, item) => {
    if (seqNoUsed.includes(e.target.value)) {
      setShowSnackbar(false);
      setSnackbarConfig({
        severity: "error",
        message: "Two images can't have same value!",
      });
      setShowSnackbar(true);
      return;
    }
    setSeqNoUsed([...seqNoUsed, e.target.value]);
    const itemIndex = findIndex(item);
    const newData = data;
    newData[itemIndex].seq = e.target.value;
    setData(newData);
    forceUpdate();
  };

  return (
    <div>
      {data.map((item, index) => (
        <div className="ad-hp">
          <FormControlLabel
            key={index}
            name={item.title}
            control={<Checkbox />}
            onChange={(e) => handleChange(e, item)}
            label={
              item.projectName === "NOTHING"
                ? item.clientName
                : item.projectName
            }
            checked={item.isChecked ? true : false}
          />
          {item.isChecked && (
            <Select
              value={item.seq === null ? "" : item.seq}
              onChange={(e) => handleSequence(e, item)}
            >
              {totalArray.map((val) => {
                return <MenuItem value={val}>{val}</MenuItem>;
              })}
            </Select>
          )}
        </div>
      ))}
      <button
        disabled={!(count === 10)}
        onClick={submitData}
        className={!(count === 10) ? "addButtonDataDisabled" : "addDataButton"}
      >
        Submit
      </button>
      <SnackBar
        isOpen={showSnackbar}
        setIsOpen={setIsOpenSnackbar}
        config={snackbarConfig}
      />
    </div>
  );
};
export default HomePage;
